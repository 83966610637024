.trial-balance-report {

  .filter-row {
    justify-content: flex-start !important;

    .date-interval-picker {
      max-width: 300px;
    }

    .checkbox {
      max-width: 200px;
    }
  }

  .report-header {
    margin-bottom: 10px;
    .export-options {
      button {
        margin-right: 10px;
      }
    }
  }
}
