.general-leader-report {

  .report-list-header {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
  }
  .account-code {
    margin-top: 0px;
    div[class*="-menu"] {
      min-width: 300px;
    }
  }
}
