.posts-page {
  position: relative;

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accounts-select {
    width: 260px;
    position: absolute;
    top: 25px;
    right: 45px;
    .css-1uccc91-singleValue {
      font-weight: 600;
      font-size: 14px;
    }
  }
  .document-list {
    padding-top: 25px;
  }

  .row-wrapper:hover.clickable {
    background-color: rgba(78, 188, 250, 0.1);
  }

  .row-wrapper:hover .delete-icon {
    display: block;
  }

  .delete-icon {
    display: none;
  }

  .delete-wrap {
    padding-left: 0;
    padding-right: 10px;
  }
 
}
.custom-button.btn-sm, .btn-group-sm > .custom-button.btn{
  min-width: 115px ;
}
