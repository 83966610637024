.currency-exchange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 370px;

  input {
    width: 80px;
  }

  .result-value {
    font-size: 14px;
  }
}
