.accountant-container-page.expanded {
  .reconcile-page {
    height: 100%;
    grid-template-columns: 1fr 380px;
    .right-side {
      padding-right: 25px;
    }
    .left-side {
      padding-left: 25px;
    }
    .account-detail {
      grid-template-columns: 180px 2fr 1fr;
    }
    .transactions-details {
      & > div:last-child {
        padding-right: 10px;
        padding-left: 5px;
      }
      & > div:first-child {
        padding-left: 10px;
        padding-right: 5px;
      }
    }
  }
}
.reconcile-page {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 450px;
  margin-top: -70px;

  .right-side {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    margin-top: 70px;
    padding: 33px 60px 30px 20px;
    min-height: calc(100vh - 80px);
    height: calc(100vh - 80px);
    overflow-y: auto;

    .no-data {
      margin-top: 250px;
      display: flex;
      align-items: center;
      color: #ACB5C0;

      .blue-questions-icon {
        margin-right: 5px;
      }
    }
  }
  .left-side > div > div {
    margin-top: 70px;
    padding: 0px 20px 0px 0px;
  }

  .title {
    margin-top: 0px;
    padding: 0px;
  }

  .search-bar {
    position: relative;
    & > span {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }
  .buttons {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    position: sticky;
    bottom: 5px;
    padding-top: 20px;
    .buttons-inline {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    button {
      width: 48%;
    }
  }
  .summary {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    margin-top: 20px;
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 30px;
    line-height: 20px;
    background: var(--unnamed-color-716dff) 0% 0% no-repeat padding-box;
    color: white;
    &.loading {
      display: block;
    }
    & > div {
      padding-left: 35px;
      padding-right: 35px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.42px;
      &:first-child {
        border-right: 1px solid var(--unnamed-color-ffffff);
      }
    }
  }
  .suggestions-title {
    font: normal normal 600 14px/18px Satoshi;
    letter-spacing: 0.42px;
    color: rgba(0, 0, 0, 1);
    padding-top: 10px;
  }
  .toggle-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    font: normal normal normal 14px/18px Satoshi;
    letter-spacing: 0.42px;
    color: rgba(0, 0, 0, 1);
  }
  .toggle-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ebedf2);
    padding: 7px 15px;
    color: var(--unnamed-color-1d1d27);
    text-align: left;
    font: normal normal 600 12px/15px Satoshi;
    letter-spacing: 0.36px;
    color: rgba(29, 29, 39, 1);
    a {
      width: 100%;
      color: var(--unnamed-color-1d1d27);
      text-align: left;
      font: normal normal bold 12px/15px Satoshi;
      letter-spacing: 0.36px;
      & > span {
        font-weight: normal;
        color: #ACB5C0;
      }
    }
    & > div:last-child {
      padding-top: 3px;
    }
  }
  .question-container {
    margin-top: 5px;
    margin-bottom: 5px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ebedf2);
    padding: 7px 15px;
    color: var(--unnamed-color-1d1d27);
    text-align: left;
    font: normal normal 600 12px/15px Satoshi;
    letter-spacing: 0.36px;
    color: rgba(29, 29, 39, 1);
    .question, .answer {
      margin-top: 3px;
    }
  }  
  .statement-container {
    margin-top: 5px;
    margin-bottom: 5px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ebedf2);
    padding: 7px 15px;
    color: var(--unnamed-color-1d1d27);
    text-align: left;
    font: normal normal 600 12px/15px Satoshi;
    letter-spacing: 0.36px;
    color: rgba(29, 29, 39, 1);
    .category, .business-partner, .comment {
      margin-top: 3px;
    }
  }    
  .transactions-header {
    background: var(--unnamed-color-1d1d27) 0% 0% no-repeat padding-box;
    font-size: 12px;
    color: var(--unnamed-color-ffffff);
    margin-top: 20px;
    text-align: center;
    padding: 5px;
  }
  .transactions-actions {
    padding: 0px;
    padding-top: 10px;
    text-align: right;
    & > button:last-child {
      margin-left: 2px;
    }
    & > button:first-child {
      margin-right: 2px;
    }
  }
  .transactions-balance {
    display: grid;
    height: 30px;
    grid-template-columns: 50% 50%;
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    font-size: 12px;
    border-bottom: 1px solid var(--unnamed-color-ebedf2);
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 30px;
      & > div:last-child {
        color: var(--unnamed-color-44dec5);
        letter-spacing: 0.36px;
        font-weight: 600;
        letter-spacing: 0.36px;
      }
      & > div:first-child {
        color: var(--unnamed-color-41485a);
        letter-spacing: 0.36px;
      }
    }
    & > div:last-child {
      text-align: right;
      padding-right: 40px;
      padding-left: 35px;
    }
    & > div:first-child {
      padding-left: 40px;
      padding-right: 35px;
    }
  }
  .transactions-details {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0px;
    & > div:last-child {
      padding-right: 40px;
      padding-left: 35px;
    }
    & > div:first-child {
      padding-left: 40px;
      padding-right: 35px;
    }
    .transactions-details-item {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 1px solid var(--unnamed-color-ebedf2);
      border-radius: 2px;
      padding: 10px 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      min-height: 80px;
      font-size: 12px;
      position: relative;
      .item-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0.39px;
        color: var(--unnamed-color-1d1d27);
        .price {
          min-width: 100px;
          text-align: right;
        }
      }
      .item-detail {
        letter-spacing: 0.36px;
        padding-top: 8px;
        color: #000000;
        opacity: 0.6;
        margin-right: 30px;
      }
      .item-indicator {
        display: flex;
        justify-content: flex-end;
        gap: 10px
      }      
      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        #arrow-right {
          width: 17px;
          height: 17px;
          #Ellipse_15 {
            fill: var(--unnamed-color-44dec5);
          }
          #Arrow {
            fill: white;
          }
        }
        &:hover {
          #arrow-right {
            width: 17px;
            height: 17px;
            #Ellipse_15 {
              fill: var(--unnamed-color-716dff);
            }
            #Arrow {
              fill: white;
            }
          }
        }
      }
      &.selected {
        background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #EAEBEE40;
        border: 1px solid #716DFF;
        border-radius: 2px;
        user-select: none;
      }
      &.reconciled {
        background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
        border: 2px solid var(--unnamed-color-44dec5);
        box-shadow: 0px 6px 12px #EAEBEE40;
        border-radius: 2px;
        user-select: none;
      }
      &.draft {
        border-style: dashed;
        opacity: 50%;
      }       
      &.is-matching:hover {
        #arrow-right {
          width: 17px;
          height: 17px;
          #Ellipse_15 {
            fill: var(--unnamed-color-716dff);
          }
          #Arrow {
            fill: white;
          }
        }
      }
      .payments-references {
        .expandable-header {
          padding-left: 0px;
          background: none;
          height: 35px;
          .toggle-button {
            position: unset;
            margin-left: 5px;
          }
        }
        .collapse, .collapsing {
          padding-left: 30px;
        }
      }
    }
    .left {
      .transactions-details-item {
        .icon {
          right: 0px;
          transform: translate(50%, -50%);
        }
      }
    }
    .right {
      .transactions-details-item {
        .icon {
          left: 0px;
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
    .post-transaction {
      padding: 20px;
      background: #ACB5C01A 0% 0% no-repeat padding-box;
      border-radius: 2px;
      margin-top: 20px;
      & > div:first-child {
        font-size: 11px;
        color: var(--unnamed-color-41485a);
        text-align: center;
        padding-bottom: 10px;
      }
      .post-button {
        text-align: center;
        button {
          width: 120px;
        }
      }
    }
  }
  .account-detail {
    display: grid;
    grid-template-columns: 250px 300px 170px;
    margin-top: 10px;
    background-color: #f5f6f780;
    border-radius: 2px 0px 0px 2px;
    & > div:not(:first-child) {
      text-align: right;
      line-height: 35px;
      color: var(--unnamed-color-1d1d27);
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.39px;
    }
    & > div {
      padding-right: 20px;
    }
    & .input-control {
      margin: 0;
    }
  }
  .no-data {
    padding: 10px;
    text-align: center;
    font-size: 14px;
  }
}
.custom-journal-entry-modal {
  .modal-title-custom {
    text-align: left !important;
  }
  .post-detail-list {
    margin: 0;
  }
}
.incoming-balance-modal {
  .content {
    display: flex;
    justify-content: space-between;
  }
}