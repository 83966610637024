.new-post-modal {
  .modal-content {
    padding: 0px 30px 25px 30px;
  }
  .header{
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;
  }
  .modal-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 0
  }
  .upload-title{
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-weight: 300;
    padding-bottom: 5px;
    padding-left: 0;
  }
  input  {
    margin-bottom: 20px;
  }
  .button-list {
    margin-top: 60px;
    text-align: right;
  }
}