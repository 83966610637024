.mx-h-400 {
  max-height: 400px;
}

.arrow-indicator {
  position: absolute;
  right: 10px;
  z-index: 100;
  bottom: 10px;

  .menu-is-open {
    svg {
      transform: rotate(180deg);
      transition: 0.5s;
    }
    .a-arrow-down-icon {
      fill: #716dff;
    }
  }
  .menu-is-hidden svg {
    transition: 0.5s;
  }
}