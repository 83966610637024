@import "../../styles/variables";
.post-detail-page {
  position: relative;

  .content {
    background: var(--unnamed-color-f5f6f7);
    padding-bottom: 40px;

    .post-detail-list {
      padding-top: 40px;
      .delete-button {
        background: var(--unnamed-color-ff2222);
        margin-right: 10px;
      }
    }
  }
  .grid-wrap {
    position: relative;
    display: grid;
  }

  .suggestion {
    position: absolute;
    right: 0;
    bottom: -10px;
    font-size: 12px;
    color: var(--unnamed-color-41485a);
    opacity: 0.6;

    & > span {
      cursor: pointer;
      color: #716DFF;
      margin-left: 3px;
      font-weight: 600;
    }
  }

  .post-image-wrap {
    position: relative;
    max-height: 546px;
    border: 1px solid var(--unnamed-color-ebedf2);
    min-height: 250px;

    &.isNewPost {
      background: #fff;
    }

    &.split-controls-open .pdf-viewer-page-controls {
      left: 450px;
    }

    .post-image {
      overflow: hidden auto;

      &.not-pdf {
        max-height: 544px;
      }

      /* custom scrollbar */
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #DEE1E6;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #41485A;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #1D1D27;
      }

      .split-pdf-button {
        width: 100px;
        height: 40.47px;
        background: $secondary;
        border-radius: 2px;
        min-width: unset;
        
      }

      .split-pdf-button-active {
        width: 80px;
        height: 30px;
        background: #44DEC5;
        border-radius: 0px 2px 2px 0px;
        min-width: unset;
        padding-top: 3px;
      }

      .split-pdf-button-exit {
        background: #41485A;
        border-radius: 2px 0px 0px 2px;
        min-width: unset;
        margin: 15px;
        border: 0.2px solid #ACB5C0;
      }

      .split-pdf-input-active * {
        height: 30px;
        width: 104px;
        border-radius: 2px 0px 0px 2px;
      }

      .split-pdf-text-active {
        color: #FFFFFF;
        font-family: Satoshish;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        margin-right: 10px;
        margin-left: 10px;
      }

      .split-icon {
        position: absolute;
        left: 20px;
        bottom: 0px;
        border-radius: 5px;
        background: #fff;
      }

      .page-split-container {
        display: flex;
        height: 40px;
        align-items: center;
        background: #41485A;
        line-height: 1;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
      }

      .zoom-icon {
        position: absolute;
        right: 20px;
        bottom: 0px;
        padding: 3px;
        border: 1px solid var(--unnamed-color-ebedf2);
        border-radius: 5px;
        background: #fff;
      }
      .image {
        width: 100%;
        margin-bottom: 70px;
      }
      .react-pdf__Document canvas {
        max-width: 100%;
      }
      .upload-file {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .header {
    padding: 10px 0px;
    height: 50px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: var(--unnamed-color-1d1d27);
      font-size: 18px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 10px;
    }
  }
  .status {
    display: flex;
    color: var(--unnamed-color-1d1d27);
    border-top: 1px solid var(--unnamed-color-ebedf2);
    border-bottom: 1px solid var(--unnamed-color-ebedf2);
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    justify-content: space-between;

    .status-wrapper {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    .status-title {
      color: #777C89;
      padding-right: 10px;
    }

    & svg {
      position: relative;
      bottom: 2px;
    }
  }
  .document-edit-buttons {
    padding-top: 35px;
    & > * {
      margin-right: 10px;
    }
  }  
  .detail-form {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 40px;

    & > * {
      width: calc(100% / 2 - 5px);
      &:nth-child(2n - 1) {
        margin-right: 10px;
      }
    }
    .custom-datepicker {
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .currency-converter {
      width: fit-content;
      background: #fff;
      color: #41485A;
      font-size: 12px;
      padding: 0 10px;
    }
  }

  .questions-to-client, .document-attachments {
    margin-top: 15px;
    .expandable-header {
      background-color: #ffffff;
      border-top: 1px solid var(--unnamed-color-f5f6f7);
      border-bottom: 1px solid var(--unnamed-color-f5f6f7);
    }
  }
  .document-attachment-items {
    & > div {
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 15px;
    }
    .document-attachment-item {
      display: grid;
      grid-template-columns: 110px 1fr;
      margin-top: 10px;
      background: #ffffff;
      border: 1px solid var(--unnamed-color-f5f6f7);
      box-shadow: 0px 6px 12px #EAEBEE40;
      padding: 10px;
      & > :first-child {
        position: relative;
      }
      .zoom-icon {
        position: absolute;
        bottom: 5px;
        right: 15px;
        width: 22px;
        height: 22px;
        border: 1px solid var(--unnamed-color-ebedf2);
        border-radius: 5px;
        background: #fff;
        & svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          fill: #4ebcfa;
          stroke-width: 0.8px;
        }
      }
    }
    .document-attachment-item-content {
      font-size: 13px;
      line-height: 15px;
      padding-top: 5px;
      & > :last-child {
        padding-top: 5px;
      }
    }
  }
  .accounting-question-items {
    & > div {
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 15px;
    }
    .accounting-question-item {
      padding: 15px 20px;
      background: #ffffff;
      border: 1px solid var(--unnamed-color-f5f6f7);
      box-shadow: 0px 6px 12px #EAEBEE40;
      margin-top: 10px;
      position: relative;
      .title {
        color: var(--unnamed-color-41485a);
        font-size: 14px;
        font-weight: normal;
      }
      .answer {
        color: #8e8e8e;
        font-size: 14px;
        padding-top: 10px;
        span {
          color: var(--unnamed-color-716dff);
        }
      }
      .answer-status {
        color: var(--unnamed-color-44dec5);
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        right: 20px;
        bottom: 20px;
        &.is-open {
          color: var(--unnamed-color-ff8886);
        }
      }
    }
  }
  .account-codes, .tax-codes {
    div[class*="-menu"] {
      min-width: 300px;
    }
  }
  .supplier {
    div[class*="-ValueContainer"] {
      font-size: 12px;
    }
    .add-new-supplier {
      padding: 10px 12px;
      border-top: 1px solid var(--unnamed-color-f5f6f7);
      text-align: center;

      button {
        width: 200px;
      }
    }
    div[class*="-group"] {
      background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
      height: 28px;
      line-height: 28px;
    }
    div[class*="-option"] {
      display: grid;
      grid-template-columns: 30px 1fr;
      & > div:first-child {
        img {
          width: 20px;
          height: 20px;
        }
      }
      & > div:last-child {
        & > div:first-child {
          font-size: 14px;
          font-weight: 600;
        }
        & > div:last-child {
          font-size: 11px;
          font-weight: 600;
          color: #ACB5C0;
        }
      }
    }
    .group-name {
      color: #ACB5C0;
      font-size: 12px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .function-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .bank-account {
    .add-new-bank-account {
      padding: 10px 12px;
      border-top: 1px solid var(--unnamed-color-f5f6f7);
      text-align: center;

      button {
        width: 200px;
      }
    }
  }
  .bg-badge{
    background:  $secondary;
  }
  .pending-icon .c{
    fill: $white-color;
  stroke: $white-color;
  }
}

.post-detail-page .create-supplier-modal,.post-detail-page .create-bank-account-modal {
  position: absolute;
  top: 128px;
  right: 19px;
  z-index: 10;
  display: none;
  width: 350px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D5DADF;
  border-radius: 2px;

  &.show {
    display: block;
  }

  & > .clear-icon {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    background: var(--unnamed-color-41485a);
    padding: 10px 20px;
    border-radius: 2px 2px 0 0;
  }

  .content {
    padding: 10px 20px;

    & > .label, .input-control .label, .select-control .label {
      text-align: left;
      font: normal normal normal 12px/15px Satoshi;
      letter-spacing: 0.36px;
      opacity: 0.7;
      padding-top: 10px;
    }

    & .radio-button .label {
      font: normal normal normal 13px/20px Satoshi;
    }
  }

  .actions {
    padding: 20px;
    text-align: center;
    background: var(--unnamed-color-f5f6f7);
    border-radius: 0 0 2px 2px;
  }
}

.je-selector {
  & .pill {
      background: #ACB5C0 0% 0% no-repeat padding-box;
      border-radius: 23px;
      opacity: 0.5;
      display: inline-block;
      padding: 4px 6px;
      margin-right: 10px;

      &.selected {
        border-color: black;
        border-style: solid;
      }
  }
}

.post-detail-page .create-bank-account-modal {
  top: 278px;

  .one-line {
    display: flex;
    align-items: flex-end;

    .bank-name {
      margin: 0 0 12px 10px;
      & span {
        display: flex;
        align-items: center;
        color: var(--unnamed-color-41485a);
        font: normal normal 600 13px/20px Satoshi;

        & > svg {
          margin-right: 5px;
        }
      }
    }

    .currency {
      margin-left: 10px;
      width: 150px;
    }
  }
}

.popover-text > div:not(:last-child) {
  margin-bottom: 10px;
}
