.general-ledger-report {
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .export-options {
      display: flex;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .filter-header {
    padding-top: 20px;
    .filter-row {
      display: flex;
      justify-content: space-between;

      .input-control {
        margin: 0;
      }

      & > * {
        flex: 1;
      }

      & > *:not(:last-child) {
        margin-right: 10px;
      }

      .shrinked {
        padding-top: 7px;
      }
    }
  }

  .report-table {
    margin: 20px 0 0;
    position: relative;
    font-size: 0.75em;

    .report-table-header {
      position: sticky;
      top: 0;
      height: 45px;
      background: var(--unnamed-color-41485a) 0% 0% no-repeat padding-box;
      color: var(--unnamed-color-ffffff);
      font-weight: 800;
      padding-left: 10px;
      padding-right: 10px;
      & > div {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    & > div {
      display: grid;
      grid-template-columns: 100px 100px 30px 30px 80px 80px 3fr 1fr 1fr 1fr 1fr;
      align-items: center;
    }
    .report-table-row {
      border: 1px solid #D5DADF;
      border-top: none;
      padding: 0 10px;
      min-height: 50px;
      & > div {
        padding-left: 5px;
        padding-right: 10px;
      }
    }
    .report-table-group-header, .report-table-group-footer {
      font-weight: 800;
      &  :first-child {
        grid-column: span 6
      }
    }
    .report-table-group-header {
      border: 1px solid #D5DADF;
    }
    .report-table-footer {
      @extend .report-table-row;
      background: #F5F6F7;
      font-weight: bold;

      .function-buttons {
        grid-column: span 5;

        & > div:nth-last-child(2) {
          display: inline-block;
          width: 300px;
          padding-left: 20px;
        }

        & > div:last-child {
          display: inline-block;
          padding-left: 20px;
        }
      }
    }

    .right-align {
      text-align: right;
    }
  }
}
