.client-container-page {
  display: grid;
  margin: 0px auto;
  padding: 20px;
  background-color: white;
  border: 1px solid #EBEDF2;
  max-width: 83.5%;
  display: block;
  @media screen and (max-width: 1599px) {
    // grid-template-columns: minmax(1186px, 1366px) 0px;
    .right-panel {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #d5dadf4d;
      z-index: 100;
      display: none;

      & > div {
        position: absolute;
        top: 10px;
        left: 50%;
        bottom: 100px;
        transform: translateX(-50%);
        max-width: 1200px;
        width: 1200px;
        z-index: 10;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #D5DADF;
        border-radius: 2px;
        background: var(--unnamed-color-ffffff);
        bottom: 10px;
      }

      &.show {
        display: block;
      }
    }
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: minmax(1186px, 1366px) minmax(400px, 1186px);

    .right-panel {
      & > div {
        height: calc(100vh - 20px);
        position: sticky;
        padding: 0 0 0 20px;
        top: 10px;
        & > .preview-window-close-button {
          display: none;
        }
      }
    }
  }
  .preview-window-close-button {
    position: absolute;
    right: -35px;
    top: 3px;
    & .dark-close-icon .b,
    & .dark-close-icon .c {
      fill: white;
      stroke: white;
    }
  }
  .title {
    color: var(--unnamed-color-1d1d27);
    font-weight: 600;
    font-size: 18px;
  }
  .title2 {
    color: var(--unnamed-color-1d1d27);
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.42px;
    .sub-title {
      font-weight: normal;
    }
  }
  .right-panel {
    & > div {
    & > iframe {
      width: 100%;
      height: 100%;
      border: none;
    }}
  }
}
