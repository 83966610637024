@import "../../../styles/variables";

//Loading
.webim-loading {
  position: fixed;
  z-index: 8;
  background-color: $iron;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.child-loading {
    position: absolute;
  }

  img {
    width: 24px;
  }

  .webim-loading span {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    height: 80px;
    text-align: center;
  }
}

.height100vh {
  height: 100vh;
}

.loadingTransparent {
  background-color: transparent;
}

// Progress Bar

.loadingBarWrapper {
  width: 100%;

  img {
    width: 100%;
    border-radius: 4px;
    height: 15px;
  }
}

.custom-loading-bar-wrapper-mobile {
  position: initial !important;
}
