.channel-list {
  height: 100%;
  border-right: 1px solid #ebedf2;

  .header {
    margin: 30px 10px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-thread {
      background: #44DEC5;
      width: 35px;
      height: 35px;
      border-radius: 2px;
      text-align: center;
      line-height: 35px;

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  .search-bar {
    margin: 0 10px 5px 0;
    position: relative;

    & > span {
      position: absolute;
      top: 4px;
      right: 15px;
    }
  }

  .items {
    border: 1px solid #EBEDF280;
    max-height: 48vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.channel-item {
  padding: 15px 20px;
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #EBEDF280;
  }

  &:hover {
    opacity: 0.6;
  }

  &.active {
    background: #f5f6f7 0% 0% no-repeat padding-box;
    border: 1px solid #f5f6f7;

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      background: #f5f6f7 0% 0% no-repeat padding-box;
      position: absolute;
      right: -10px;
      top: calc(50% - 8px);
    }
  }

  &.with-new-messages .info .thread-name {
    font-weight: bold;
    color: #1D1D27;
  }

  .info {
    display: grid;
    grid-template-columns: 7fr 1fr auto;
    align-items: center;

    .thread-name {
      color: #41485a;
      font-weight: 600;
    }

    .counter {
      justify-self: end;
      width: 16px;
      height: 16px;
      margin: 0 6px;
      background: #44DEC5;
      color: #fff;
      font-size: 11px;
      border-radius: 50%;
      text-align: center;
    }

    .date {
      color: #ACB5C0;
      justify-self: end;
    }
  }

  .last-message {
    color: #ACB5C0;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
