.bank-account {
  .add-new-bank-account {
    padding: 10px 12px;
    border-top: 1px solid var(--unnamed-color-f5f6f7);
    text-align: center;

    button {
      max-width: 100%;
    }
  }
}
