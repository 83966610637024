.all-contracts-overview-page {
  padding: 30px 60px;

  .contract-group {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    color: var(--unnamed-color-41485a);
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    border-bottom: 1px solid var(--unnamed-color-ebedf2);
    letter-spacing: 0.39px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    font-size: 14px;
    line-height: 15px;
    font-weight: bold;
    gap: 10px;
    & > div {
      display: table;
      & > div {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .contract-item {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    column-gap: 5px;
    padding-left: 30px;
    height: 45px;
    font-size: 13px;
    color: var(--unnamed-color-1d1d27);
    &:nth-child(odd) {
      background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    }
    .link-button-text {
      font-size: 12px;
      text-decoration: underline;
    }
  }
}