.overview-page {
  padding: 30px 60px;

  .summary {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    opacity: 0.7;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    &.loading {
      display: block;
    }
    & > div {
      padding-left: 25%;
      & > div:first-child {
        color: var(--unnamed-color-41485a);
        font-size: 12px;
      }
      & > div:last-child {
        color: var(--unnamed-color-41485a);
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.48px;
        svg {
          display: inline-block;
          margin-top: -6px;
        }
      }
    }
  }
  .receivables-group, .bank-accounts-group {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    height: 45px;
    color: var(--unnamed-color-41485a);
    padding-left: 30px;
    padding-top: 10px;
    margin-top: 30px;
    border-bottom: 1px solid var(--unnamed-color-ebedf2);
    letter-spacing: 0.39px;
  }
  .receivables-item {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 150px 120px;
    column-gap: 5px;
    padding-left: 30px;
    height: 45px;
    font-size: 12px;
    padding-right: 50px;
    color: var(--unnamed-color-1d1d27);
    &:nth-child(even) {
      background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    }
    .link-button-text {
      font-size: 12px;
      text-decoration: underline;
    }
  }
  .payables-item {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 150px 120px 40px 0.75fr;
    column-gap: 5px;
    padding-left: 30px;
    height: 45px;
    font-size: 12px;
    padding-right: 50px;
    color: var(--unnamed-color-1d1d27);
    &:nth-child(even) {
      background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    }
    .link-button-text {
      font-size: 12px;
      text-decoration: underline;
    }
  }
  .bank-accounts-item {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 150px 120px;
    column-gap: 5px;
    padding-left: 30px;
    height: 45px;
    font-size: 12px;
    padding-right: 50px;
    color: var(--unnamed-color-1d1d27);
    &:nth-child(even) {
      background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    }
    .link-button-text {
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .no-data-item {
    grid-column: 1 / 4;
  }

  .amount {
    text-align: right;
  }

  .payment {
    border-radius: 2px;
    text-align: end;
    line-height: 35px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .payment-disabled {
    border-radius: 2px;
    text-align: end;
    line-height: 35px;
    pointer-events:none;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .total {
    justify-self: end;
  }
}