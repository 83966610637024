.recipients-select {

  div[class*="-placeholder"] {
    font-size: 12px;
  }

  .custom-option {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    color: #41485A;
    background: transparent;
    & > span {
      color: #41485A;
      font-weight: 400;
      font-size: 13px;
    }
    & > .default-logo {
      color: #fff;
    }

    &.selected {
      & > span {
        font-weight: 600;
        color: #41485A;
      }
      & > .default-logo {
        color: #fff;
      }
    }

    &.is-company {
      background: #F5F6F7;
      margin-bottom: 2px;
    }

    & > .name {
      margin: 0 5px;
    }
  }

  .light-text {
    color: #ACB5C0 !important;
  }

  div[class*="-multiValue"] {
    border: 1px solid #F5F6F7;
    background: transparent;

    & > div:first-child {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 5px;
      align-items: center;
    }

    & > div:last-child:hover {
      background: none;
    }
  }

  .hide {
    display: none;
  }
}
