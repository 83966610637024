.messages-list {
  position: relative;
  height: 100%;

  .text-field {
    position: relative;

    .send-icon {
      position: absolute;
      bottom: 40px;
      right: 40px;
    }

    .new-message {
      width: calc(100% - 40px);
      background: #f5f6f7;
      opacity: 1;
      margin: 10px 20px;
      padding: 5px 50px 5px 20px;
      height: 70px;
      border: none;
      resize: none;
      outline: none;
    }
  }

  .header {
    position: relative;
    padding: 10px 25px;
    border-bottom: 1px solid #ebedf2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-info {
      .title {
        margin-bottom: 6px;
      }

      .time {
        font-size: 10px;
      }
    }

    .users-wrap {
      display: flex;
      align-items: flex-end;

      .users-list {
        display: flex;
        .user-logo {
          margin-left: -6px;
          cursor: pointer;
        }
      }
      
      .add-new-user {
        margin-left: 5px;
        svg {
          height: 24px;
        }
      }
    }
  }

  .items {
    min-height: 45vh;
    max-height: 65vh;
    overflow-y: scroll;

    .item {
      padding: 10px 25px;
      display: grid;
      grid-template-columns: 60px 1fr 60px;
      column-gap: 5px;

      &.unread {
        background: linear-gradient(to right, #f5f6f7 0%, #fcfcfc 100%);
      }

      .content {
        .username {
          font-size: 14px;
          color: #41485a;
          letter-spacing: 0.36px;
          font-weight: 600;
          padding-bottom: 5px;
        }

        .message {
          font-size: 14px;
          color: #1d1d27;
        }
      }

      .time {
        justify-self: end;
        font-size: 12px;
        color: #ACB5C0;
      }
    }

    .item-right {
      padding-top: 10px;
      display: grid;
      grid-template-columns: 1fr 60px 60px;
      column-gap: 5px;

      &:not(:first-child) {
        margin-top: 20px;
      }

      .content {
        justify-self: end;
        align-self: center;

        .message {
          font-size: 14px;
          color: #1d1d27;
        }
      }

      .time {
        justify-self: end;
        font-size: 12px;
        color: #ACB5C0;
      }

      .profile-image {
        justify-self: end;
      }
    }

    .item-time {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      user-select: none;

      span {
        border: 1px solid #EBEDF280;
        border-radius: 16px;
        font-size: 10px;
        color: #41485a;
        padding: 3px 15px;
      }
    }


    // questions section is undefined
    .item-time + .item, .item-time + .item-question {
      margin-top: 0 !important;
    }

    .item-question {
      padding-top: 10px;
      display: grid;
      grid-template-columns: 1fr 60px;
      column-gap: 5px;

      &:not(:first-child) {
        margin-top: 30px;
      }

      & > div:first-child {
        background: #f5f6f7 0% 0% no-repeat padding-box;
        border: 1px solid #f5f6f7;
        box-shadow: 0 6px 12px #EAEBEE40;
      }

      .content {
        padding-top: 15px;
        padding-bottom: 15px;
        display: grid;
        grid-template-columns: 60px 1fr;
        column-gap: 5px;

        & > div:first-child {
          padding-left: 15px;
          padding-top: 5px;
        }

        & > div:last-child {
          font-size: 14px;
          color: #1d1d27;
        }

        .question-detail {

          .question-title {
            color: #41485a;
            font-size: 12px;
            font-weight: bold;
            font-style: italic;
            letter-spacing: 0.36px;
          }

          .answear {

            & > span:first-child {
              font: normal normal normal 10px/13px Satoshi;
              letter-spacing: 0.3px;
              color: #ACB5C0;
              margin-right: 10px;
            }

            & > span:last-child {
              color: #716dff;
              font: normal normal bold 12px/15px Satoshi;
              letter-spacing: 0.36px;
            }
          }
        }
      }

      .time {
        font-size: 12px;
        color: #ACB5C0;
      }
    }

    &.centered {
      text-align: center;
      padding: 50px 0;
    }
  }

  .spinner-container {
    position: absolute;
    left: 50%;
    top: -15px;
    transform: translateX(-50%);
  }
}
