@import 'src/styles/variables';

.footer-bar {
  background: var(--unnamed-color-1d1d27) 0% 0% no-repeat padding-box;
  color: var(--unnamed-color-ffffff);
  & > div {
    max-width: 1366px;
    margin: 0 auto;
    position: relative;
  }
  .footer-line {
    border: 1px solid var(--unnamed-color-ffffff);
    opacity: 0.2;
  }
  .copyright {
    font-size: 10px;
    padding: 8px;
    text-align: center;
    opacity: 0.8;
  }
  .opacity6 {
    opacity: 0.6;
  }
  .footer-content {
    padding: 30px 10vw 80px;
    font-size: 12px;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr 1fr;
    & > div:not(:first-child) {
      padding-top: 10px;
    }
    .main-text-wrap {
      grid-row: 1;
      grid-column: 1 / 3;
      .main-text {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
      }
    }
    .links1, .links2 {
      opacity: 0.8;
      & > div:not(:last-child) {
        padding-bottom: 10px;
      }
    }
    .links1 {
      grid-row: 3;
      grid-column: 1;
    }
    .links2 {
      grid-row: 3;
      grid-column: 2;
    }
    .contact-us {
      grid-row: 2;
      grid-column: 1 / 3;
      .contact {
        opacity: 1;
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        .input-control {
          margin: 0 7px 0 0;
        }
        input {
          width: 200px;
        }
      }
    }
  }
}

@media (min-width: $mobile-breakpoint) {
  .footer-bar {
    .footer-content {
      padding: 30px 120px 80px;
      grid-template-columns: 2fr 1fr 1fr 2fr;
      & > div:not(:first-child) {
        padding-top: 34px;
      }
      .main-text-wrap, .links1, .links2, .contact-us {
        grid-row: initial;
        grid-column: initial;
      }
    }
  }
}
