.currency-input-control {
  position: relative;
  &.no-padding {
    margin: 0px;
  }

  .currency-dropdown-input-wrap {
    display: flex;
    border: 1px solid #D5DADF;
    border-radius: 2px;
  }

  div[class*="-option"] {
    padding: 8px 4px;
    text-align: center;
  }
  .css-9gakcf-option {
    background: rgba(172, 181, 192, 0.15) 0% 0% no-repeat padding-box;
    color: var(--unnamed-color-716dff);
    font-weight: 600;
  }
  .css-9gakcf-option * {
    color: var(--unnamed-color-716dff);
  }

  input {
    width: 100%;
    min-width: 0;
    border: none;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 33px;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 10px;
    color: var(--unnamed-color-1d1d27);
    outline: none !important;
  }
  .label {
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 5px;

    &.required:after {
      content: '*';
      color: var(--unnamed-color-ff8886);
    }
  }

  &.input-lg {
    input {
      height: 38px;
    }
  }
  &.input-sm {
    input {
      font-size: 12px;
    }
    input {
      height: 28px;
    }
  }
  &.input-md {
    input {
      font-size: 12px;
    }
    input {
      height: 33px;
    }
  }
  &.invalid {
    .label {
      color: #ff0000!important;
    }
    .currency-dropdown-input-wrap {
      border-color: #ff000080 !important;
    }
  }
}
