.chip {
  display: table;
  margin: 3px 10px 3px 0;
  background: #41485a;
  border-radius: 15px;
  padding: 5px 15px;
  position: relative;
  padding-right: 30px;

  .chip-value {
    color: #fff;
    font: normal normal 600 12px/20px Satoshi;
    margin-right: 5px;
    vertical-align: middle;
    display: table-cell;
  }

  .chip-delete-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 10px;
  }
}
