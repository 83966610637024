.messages-screen {
  height: 100%;
  padding-left: 60px;
  display: grid;
  grid-template-columns: 280px 1fr;
  column-gap: 0;
  position: relative;
}

.master-data-modal {

  .modal-80w {
    max-width: 1246px;
    max-height: 642px;
  }

  .modal-content {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box !important;
    opacity: 1;
  }

  .modal-container {
    height: 492px;
  }

  .modal-title-wrapper {
    .modal-title-custom {
      text-align: left !important;
      letter-spacing: 0.6px;
      opacity: 1;
    }

    .close-icon {
      right: 20px !important;
      top: 20px !important;
    }
  }

  .modal-body-wrapper {
    padding: 0 40px 68px;
  }

  .content {
    display: grid;
    grid-template-columns: 260px 896px;
    grid-column-gap: 10px;
    height: 100%;
  }

  .left-panel {
    display: flex;
    flex-direction: column;

    .search-bar {
      position: relative;

      .input-control {
        margin-top: 0;
        margin-bottom: 7px;
      }

      & > span {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    .tabs-list {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 1px solid #D5DADF;
      border-radius: 2px;
      opacity: 1;
      flex: 1;
      padding-top: 12px;

      .tab-item {
        line-height: 36px;
        height: 36px;
        display: block;
        text-align: left;
        color: var(--unnamed-color-1d1d27);
        font: normal normal 600 14px/18px Satoshi;
        letter-spacing: 0.56px;
        padding: 9px 0 9px 20px;

        &:hover {
          color: black;
          font-weight: bold;
        }

        &.active {
          color: var(--unnamed-color-716dff);
          background: var(--unnamed-color-f5f6f7);
          font-weight: bold;
        }
      }
    }
  }

  .right-panel {
    .business-partner-list {
      border: 1px solid #D5DADF;
      border-top: none;
      height: 100%;
      display: flex;
      border-radius: 2px;
      flex-direction: column;

      .business-partner-gridview {
        flex: 1;

        .common-table .table-header-column {
          color: var(--unnamed-color-ffffff);
          text-align: left;
          font: normal normal normal 13px/16px Satoshi;
          letter-spacing: 0.39px;
          opacity: 1;
          padding-right: 0;
          padding-left: 23px;
        }

        .common-table .table-row .table-cell {
          color: var(--unnamed-color-41485a);
          text-align: left;
          font: normal normal 600 13px/20px Satoshi;
          letter-spacing: 0;
          padding-right: 0;
          padding-left: 23px;
          opacity: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .common-table {
          .delete-wrap {
            visibility: hidden;
          }

          .table-row {
            &:hover {
              .delete-wrap {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  .detail-form {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: calc(100% / 2 - 12.5px);
      margin-bottom: 16px;

      &:nth-child(2n - 1) {
        margin-right: 25px;
      }
    }

    .select-control {
      .select > div {
        min-height: inherit;
      }
    }
  }

  .detail-page {
    height: 100%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;

    .add-button {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 200px;
      height: 40px;
      .button-text {
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal normal 13px/18px Satoshi;
        letter-spacing: 0.42px;
        opacity: 1;
      }
    }

    .edit-page {
      padding: 0 180px 0 20px;
      height: 100%;
      background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;

      .save-button {
        position: absolute;
        width: 200px;
        height: 40px;
        right: 221px;
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal normal 14px/18px Satoshi;
        letter-spacing: 0.42px;
        opacity: 1;
      }
      .radio-group {
        .label {
          color: var(--unnamed-color-41485a);
          text-align: left;
          font: normal normal normal 12px/15px Satoshi;
          letter-spacing: 0.36px;
          opacity: 0.7;
          padding-bottom: 10px;
        }

        .radio-button {
          .label {
            text-align: left;
            font: normal normal 600 13px/20px Satoshi;
            letter-spacing: 0;
            color: #1D1D27;
            opacity: 1;
          }
        }
      }



      .input-control {
        .label {
          color: var(--unnamed-color-41485a);
          text-align: left;
          font: normal normal normal 12px/15px Satoshi;
          letter-spacing: 0.36px;
          opacity: 0.7;
          padding-bottom: 10px;

          &.required:after {
            content: '*';
            color: var(--unnamed-color-ff8886);
          }

          &.invalid {
            color: #ff0000;
          }
        }
      }
    }

    .header {
      display: flex;
      margin-bottom: 7px;

      .back-button {
        svg {
          transform: rotate(180deg);
        }

        margin-left: -16px;
        width: 33px;
        height: 33px;
        display: inline-block;
        cursor: pointer;

        #Ellipse_15, #Arrow {
          transition: fill .5s ease;
        }

        #Arrow {
          fill: var(--unnamed-color-1d1d27);
        }

        &:hover {
          #Ellipse_15 {
            fill: #716dff;
            transition: fill .5s ease;
          }

          #Arrow {
            fill: white;
            transition: fill .5s ease;
          }
        }
      }

      .title {
        font-size: 20px;
        margin-left: 8px;
      }
    }

    .one-line {
      display: flex;
      align-items: flex-end;

      .check-box {
        display: flex;
        align-self: center;

        .check-box-2 {
          margin-top: 15px;
        }
      }
      .bank-name {
        margin: 0 0 12px 10px;

        & span {
          display: flex;
          align-items: center;
          color: var(--unnamed-color-41485a);
          font: normal normal 600 13px/20px Satoshi;

          & > svg {
            margin-right: 5px;
          }
        }
      }

      .currency {
        margin-left: 10px;
        width: 150px;
      }
    }
  }

}
