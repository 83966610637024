.default-logo {
  border-radius: 50%;
  color: #ffffff;
  font-weight: 600;
  text-align: center;

  &--sm {
    font-size: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  &--md {
    font-size: 16px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  &--lg {
    font-size: 20px;
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
}
