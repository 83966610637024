.pdf-viewer {
  position: relative;
  // padding-bottom: 70px;

  .spinner-container {
    position: absolute;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    z-index: 10;

    .spinner {
      width: 3rem;
      height: 3rem;
      color: #bcbcbc !important;
    }
  }
}

.pdf-viewer-page-controls {
  position: absolute;
  bottom: 22px;
  left: 50%;
  background: white;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 10px 30px 0 rgba(126, 143, 191, 0.2);
  border-radius: 4px;
  span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
  }
  button {
    width: 35px;
    height: 35px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
    outline: none !important;
    &:hover {
      background-color: #e6e6e6;
    }
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

@media (min-width: 1024px) {
  div.rpv-default-layout__sidebar--opened {
    width: 10rem;
  }
}

div.rpv-default-layout__sidebar--opened {
  .rpv-thumbnail__items > div {
    max-width: 100%;
    & .rpv-thumbnail__container,
    & .rpv-thumbnail__container img {
      max-width: 100%;
    }
  }
}
