.journal-entry-details-modal {
  .modal-dialog {
    max-width: 1000px;
  }

  .modal-title-wrapper {
    padding-bottom: 40px !important;
  }

  .back-icon {
    margin-right: 10px;

    svg {
      margin-bottom: 3px;

      .a {
        fill: #1D1D27;
      }
    }
  }

  .row { padding: 10px 0;}
  .current-line { font-weight: bold; }
  .actions {
    text-align: center;
    padding-top: 30px;
  }

  .content {
    padding-left: 40px;
    padding-right: 40px;

    .custom-select-option-template {
      display: flex;
      justify-content: space-between;
    }

    .add-new-template {
      padding: 10px 12px;
      border-top: 1px solid var(--unnamed-color-f5f6f7);
      text-align: center;

      button {
        width: 200px;
      }
    }

    & > .label, .input-control .label, .select-control .label {
      text-align: left;
      font: normal normal normal 12px/15px Satoshi;
      letter-spacing: 0.36px;
      opacity: 0.7;
      padding-bottom: 7px;
      padding-top: 20px;
    }

    span.add-icon {
      width: 30px;
      height: 30px;
      background: #ACB5C0 0% 0% no-repeat padding-box;
      border-radius: 23px;
      opacity: 0.5;
      display: inline-block;
      padding-left: 6px;
      padding-top: 0px;
    }

    .answer-item {
      position: relative;

      &:not(:first-child) {
        margin-bottom: 10px;
      }

      .char {
        user-select: none;
        font: normal normal normal 13px/26px Satoshi;
        letter-spacing: 0px;
        color: #ACB5C0;
        position: absolute;
        top: 5px;
        left: 10px;
      }

      input {
        padding-left: 30px;
      }
    }

    .radio-button:not(:last-child) {
      padding-right: 40px;
    }

    .two-columns {
      display: grid;
      grid-template-columns: 66.6667% 33.3333%;

      & > *:not(:last-child) {
        padding-right: 10px;
      }
    }

    .three-columns {
      display: grid;
      grid-template-columns: 33.3333% 33.3333% 33.3333%;

      & > *:not(:last-child) {
        padding-right: 10px;
      }
    }
  }

  .label-wrap > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font: normal normal normal 14px/18px Satoshi;
    color: #ACB5C0;
  }

  .send-button + .send-button {
    margin-left: 10px;
  }
}
