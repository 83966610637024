.confirm-modal {

  .actions {
    display: flex;
    justify-content: center;

    button:last-child {
      margin-left: 10px;
    }
  }
}
