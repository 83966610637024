.internal-reconciliation-page {
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-header {
    padding-top: 20px;
    .filter-row {
      display: flex;
      justify-content: space-between;

      .input-control {
        margin: 0;
      }

      & > * {
        flex: 1;
      }

      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .postings-list-container {
    font-size: 0.75em;

    .postings-list {
      margin: 20px 0 0;
      position: relative;

      .postings-list-header {
        height: 45px;
        background: var(--unnamed-color-41485a) 0% 0% no-repeat padding-box;
        color: var(--unnamed-color-ffffff);
        font-weight: 800;
        padding-left: 10px;
        padding-right: 10px;
        & > div {
          padding-left: 5px;
          padding-right: 10px;
        }
      }
      & > div {
        display: grid;
        grid-template-columns: 30px 100px 30px 30px 50px 30px 3fr 1fr 1fr 1fr;
        align-items: center;
      }
      .postings-list-item {
        border: 1px solid #d5dadf;
        border-top: none;
        padding: 0 10px;
        min-height: 50px;
        & > div {
          padding-left: 5px;
          padding-right: 10px;
        }
      }
      .postings-list-footer {
        @extend .postings-list-item;
        background: #f5f6f7;
        font-weight: bold;

        .function-buttons {
          grid-column: span 7;

          & > div:nth-last-child(2) {
            display: inline-block;
            width: 300px;
            padding-left: 20px;
          }

          & > div:last-child {
            display: inline-block;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
