.post-status-wrap {
  display: flex;

  &.status-grey {
    color: #ACB5C0;

    // grey color for icon
    .c {
      fill: #ACB5C0;
      stroke: #ACB5C0;
    }
  }
}