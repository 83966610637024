@import '../../styles/variables';
.login-form {
  padding: 30px 40px;
  position: absolute;
  width: 450px;
  max-width: 90%;
  right: 5%;
  top: 10%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #332FAE61;
  border: 1px solid #EBEDF280;
  border-radius: 4px;
  z-index: 2;

  .customInputPadding {
    padding-left: 46px;
    &:focus{
      padding-left: 46px;
    }
  }

  @media (max-width: 768px) {
    right: unset;
    left: 50%;
    transform: translatex(-50%);
    top: 150px;
  }
  @media (min-width: 1441px) {
    width: 650px;
    span.custom-text-body.button-text {
      font-size: 16px !important;
    }
  }
  .title {
    color: var(--unnamed-color-41485a);
    letter-spacing: 0.9px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 30px;
  }
  .forgot-password {
    margin-top: 5px !important;
    .btn-link {
      text-decoration: none;
    }
    span {
      font-size: 13px;
    }
  }
  .login-button {
    margin-top: 20px;
    width: 260px;
  }
  .forgotten-text span{
    color: $warning ;

  }
}
.icon-space{
  margin-left: 13px;
  margin-top: 14px;
}

.shouldCenter {
  left: unset;
  right: calc(50% - 225px);
  transform: unset
}


@media (min-width: 1366px) {
  .login-form {
    padding-top: 54px;
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    position: absolute;
    width: 590px;
  }

  .shouldCenter {
    left: unset;
    right: calc(50% - 295px);
  }
}
@media (min-width: 1920px) {
  .login-form {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 70px;
    padding-right: 70px;
    position: absolute;
    width: 664px;
  }

  .shouldCenter {
    left: unset;
    right: calc(50% - 332px);
  }
}
