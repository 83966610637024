.messages-screen {
  height: 100%;
  padding-left: 60px;
  display: grid;
  grid-template-columns: 280px 1fr;
  column-gap: 0;
  position: relative;
}

.new-thread-modal {
  .modal-content {
    background: #F5F6F7;
  }

  &__input .label {
    font-size: 12px;
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-weight: 300;
    padding-bottom: 5px;
    &.required:after {
      content: '*';
      color: var(--unnamed-color-ff8886);
    }
    &.invalid {
      color: #ff0000;
    }
  }

  .text-textarea {
    width: 100%;
    padding: 10px;
    height: 120px;
    border: 1px solid #D5DADF;
    border-radius: 2px;
    resize: none;
    outline: none;
    font-size: 12px;
    margin-bottom: 30px;
    &.invalid {
      border-color: #ff000080;
    }
  }

  .button-start {
    text-align: center;
  }
}

.manage-recipients-select {
  position: absolute;
  right: 24px;
  top: 20px;
  z-index: 1;
  width: 290px;
  box-shadow: 0 3px 6px #00000029;
  display: none;

  &.show {
    display: block;
  }

  & > .close-icon {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  & > .select-title {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    background: var(--unnamed-color-41485a);
    padding: 10px 20px;
    border-radius: 2px 2px 0 0;
  }

  .select-control {
    margin-top: 0;
  }

  .select > div {
    min-height: 35px;
    border: 1px solid #D5DADF;
    border-radius: 0;
  }

  div[class*="-IndicatorsContainer"] {
    display: none;
  }

  div[class*="-menu"] {
    border-top: none;
    border-radius: 0 0 2px 2px;
    margin-top: 0;
  }
}
