.signup-form {
  padding: 30px 40px;
  position: absolute;
  width: 450px;
  max-width: 90%;
  right: 5%;
  top: 10%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #332FAE61;
  border: 1px solid #EBEDF280;
  border-radius: 4px;
  z-index: 2;
  
  @media (max-width: 768px) {
    right: unset;
    left: 50%;
    transform: translatex(-50%);
    top: 150px;
  }
  @media (min-width: 1441px) {
    width: 650px;
    span.custom-text-body.button-text {
      font-size: 16px !important;
    }
  }
  
  & > form {
    width: 100%;
    & > div:not(:first-child) {
      margin-top: 8px;
    }
    .title {
      color: var(--unnamed-color-41485a);
      letter-spacing: 0.9px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 30px;
    }
    .forgot-password {
      margin-top: 5px !important;
      span {
        font-size: 13px;
        color: var(--unnamed-color-716dff) !important;
      }
    }
    .signup-button {
      margin-top: 20px;
      width: 260px;
    }
  }

  .customInputPadding {
    padding-left: 46px;
    &:focus{
      padding-left: 46px;
    }
  }

  .org-label {
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-size: 14px;
    font-weight: 300;
  
    &.required:after {
      content: '*';
      color: var(--unnamed-color-ff8886);
    }
  }

  ul {
    font-size: small;
    margin-top: 2px;
    padding-left: 1rem;
    color: #aeaeae;
  }

  .login-link {
    span {
      font-size: 13px;
    }
  }
  .dropdown-menu{
    border-radius: 14px;
  }
}

.field {
  input {
    width: 100%;
    min-width: 0;
    border: 1px solid #D5DADF;
    border-radius: 2px;
    height: 45px;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--unnamed-color-1d1d27);
    outline: none !important;
    caret-color: var(--unnamed-color-716dff);
  }
  
  
  label {
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
  
    &:after {
      content: '*';
      color: var(--unnamed-color-ff8886);
    }
  }
}

.shouldCenter {
  left: unset;
  right: calc(50% - 225px);
  transform: unset
}


@media (min-width: 1366px) {
  .signup-form {
    padding-top: 54px;
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    position: absolute;
    width: 590px;
  }

  .shouldCenter {
    left: unset;
    right: calc(50% - 295px);
  }
}
@media (min-width: 1920px) {
  .signup-form {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 70px;
    padding-right: 70px;
    position: absolute;
    width: 664px;
  }

  .shouldCenter {
    left: unset;
    right: calc(50% - 332px);
  }
}

.company-dropdown-input {
  width: 100%;
  min-width: 0;
  border: 1px solid #D5DADF;
  border-radius: 14px;
  height: 55px;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--unnamed-color-1d1d27);
  outline: none !important;
  caret-color: var(--unnamed-color-716dff);
}
.error {
  border: 1px solid var(--unnamed-color-ff8886) !important;
}
