.async-select-control {
  position: relative;
  &.no-padding {
    margin: 0px;
  }
  .async-select-menu {
    z-index: 99;
  }
  .select > div {
    min-height: 45px;
    border: 1px solid #D5DADF;
    border-radius: 2px;
    span[class*="-indicatorSeparator"] {
      display: none;
    }
  }
  .label {
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 5px;

    &.required:after {
      content: '*';
      color: var(--unnamed-color-ff8886);
    }
  }
  div[class*="-menu"] {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D5DADF;
    border-radius: 2px;
    margin-top: 2px;
  }
  .css-9gakcf-option {
    background: rgba(172, 181, 192, 0.15) 0% 0% no-repeat padding-box;
    color: var(--unnamed-color-716dff);
    font-weight: 600;
  }
  .css-9gakcf-option * {
    color: var(--unnamed-color-716dff);
  }
  &.select-sm {
    .select > div {
      font-size: 12px;
      &[class*="-control"] {
        min-height: 30px;
        height: 30px;
      }
      &[class*="-menu"] {
        min-height: 30px;
      }
      div[class*="-NoOptionsMessage"] {
        padding: 3px 12px;
      }
      div[class*="-indicatorContainer"] {
        padding: 6px;
      }
    }
  }
  &.select-md {
    .select > div {
      font-size: 12px;
      &[class*="-control"] {
        min-height: 35px;
        height: 35px;
      }
      &[class*="-menu"] {
        min-height: 35px;
      }
      div[class*="-indicatorContainer"] {
        padding: 6px;
      }
      div[class*="-NoOptionsMessage"] {
        padding: 3px 12px;
      }
    }
  }
  &.invalid {
    .label {
      color: #ff0000;
    }
    .select > div[class*="-control"] {
      border-color: #ff000080;
    }
  }
  & .menu-is-open {
    svg {
      transform: rotate(180deg);
      transition: 0.5s;
    }
    .a-arrow-down-icon {
      fill: #716dff;
    }
  }
  & .menu-is-hidden svg {
    transition: 0.5s;
  }
}
