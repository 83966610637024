@import 'src/styles/variables';

.header-bar {
  height: 70px;
  background: var(--unnamed-color-171E44) 0% 0% no-repeat padding-box;
  color: white;
  position: relative;
  z-index: 100;
  & > div {
    margin: 0 20px;
    height: 100%;
    position: relative;
  }
  .logo {
    height: 35px;
    padding-left: 0;
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    &.accountant {
      display: grid;
      grid-template-columns: 1fr 4fr 4fr;
      align-items: center;
      height: 100%;
    }
  }
  .left-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .left-header, .menu {
    margin-left: 10px;
    .profile, .language {
      height: 35px;
      background: #FFFFFF24 0% 0% no-repeat padding-box;
      border-radius: 2px;
      margin-top: -5px;
      margin-left: 30px;
      padding: 5px 15px;
      cursor: pointer;
      .name {
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal normal 13px/20px Satoshi;
        letter-spacing: 0.13px;
      }
      .profile-image {
        width: 22px;
        height: 22px;
        fill: white;
        margin-top: -1px;
      }
    }
    .profile {
      min-width: 220px;
      display: flex;
      justify-content: space-between;
      align-items: center;
       border-radius: 40px;
      & .a-arrow-down-icon {
        fill: #fff;
      }
    }
    .profile-name-img-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 4px;
    }
    .dropdown-menu {
      min-width: 220px;
      max-height: 450px;
      overflow-y: auto;
    }
    .language {
      margin-left: 0px;
      padding-top: 7px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .dropdown {
      margin: 0px;
      padding: 0px;
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0;
    & > *:not(:first-child):not(:last-child) {
      margin-left: 20px;
    }
    & > *, & > * > .sidebar-link {
      color: var(--unnamed-color-ffffff);
      font-size: 14px;
      padding-bottom: 3px;
      display: inline-table;
    }
    .sidebar-link {
      text-decoration: none;
      &.active {
        border-bottom: 2px solid #FFF;
      }
      &.icon {
        margin-right: 17px;
        position: relative;
        svg {
          transform: rotate(90deg);
          margin-left: 1px;
          position: absolute;
          &#arrow-right {
            width: 20px;
            height: 20px;
            #Ellipse_15 {
              fill: transparent;
            }
            #Arrow {
              fill: white;
            }
          }
        }
      }
    }
    .notification {
      width: 35px;
      height: 35px;
      background: $white-color 0% 0% no-repeat padding-box;
      border-radius: 18px;
      margin-top: -5px;
      padding-left: 10px;
      padding-top: 5px;
    }
  }
  & .dropdown-item.focused {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
  }

  .header-item {
    font-weight: bold;
    cursor: pointer;
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x) !important;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap;

    &:hover {
      color: var(--bs-dropdown-link-hover-color);
      background-color: var(--bs-dropdown-link-hover-bg);
    }
  }
}
