.labels-select {
  margin-top: 7px;
  margin-bottom: 7px;
  position: absolute;
  top: 0;
  right: 30px;
  z-index: 1;
  width: 260px;
  display: none;

  &.show {
    display: block;
  }

  & > .close-icon {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  & > .select-title {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    background: var(--unnamed-color-41485a);
    padding: 10px 20px;
    border-radius: 2px 2px 0 0;
  }

  .add-new-label {
    padding: 10px 12px;
    border-top: 1px solid var(--unnamed-color-f5f6f7);
    text-align: center;

    button {
      width: 170px;
    }
  }

  .counter {
    color: #41485A;
    margin-right: 10px;
  }

  .select > div {
    min-height: 35px;
    font: normal normal normal 14px/18px Satoshi;
    border: 1px solid #D5DADF;
    border-radius: 0;
    box-shadow: none;
  }

  div[class*="-Group"] {
    padding: 0;
  }

  div[class*="-group"] {
    background: #F5F6F7;
    padding: 8px 20px;
    margin-bottom: 0;
    color: #1D1D27;
    font-size: 13px;
    font-weight: 600;
  }

  div[class*="-menu"] {
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #D5DADF;
    border-top: none;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    margin-top: 0;
  }

  div[class*="-option"] {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    font-size: 12px;
    color: #41485A;
    background: transparent;

    &.selected {
      font-weight: 600;
    }

    & span {
      margin-left: 5px;
    }
  }
}

