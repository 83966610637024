@import "../../../styles/variables";

$day-horizontal-margin: 0rem;
$row-height: 36px;

.custom-monthpicker,
.custom-datepicker,
.custom-weekpicker {
  &.invalid {
    .label {
      color: #ff0000;
    }
    .date-input.form-control, .react-datepicker__input-container > input {
      border-color: #ff000080;
    }
  }
  .react-datepicker__week,
  .react-datepicker__month-wrapper {
    margin-bottom: 0.332rem;
    &:last-child {
      margin-bottom: 0.166rem;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__day-name, 
  .react-datepicker__day, 
  .react-datepicker__time-name {
    display: inline-block;
    width: $row-height;
    line-height: $row-height;
    text-align: center;
    margin: 0px $day-horizontal-margin;
  }

  .react-datepicker__day-names > * {
    color: $text-secondary-color;
    margin-top: 1rem;
  }

  .react-datepicker__triangle {
    &::before {
      border-bottom-color: white;
    }
  }
  .react-datepicker {
    border-radius: 0px;
    box-shadow: 0px 6px 12px #EAEBEE40;;
  }
  .react-datepicker__header {
    border-bottom: none;
    background-color: white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: #000;
  }
  .react-datepicker {
    // border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .react-datepicker__input-container {
    &:after {
      cursor: pointer;
      content: '';
      background: url("../../../assests/icons/icCalendar.svg") no-repeat;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      width: 20px;
      pointer-events: none;
    }

    & > input {
      width: 100%;
      border: 1px solid #D5DADF;
      border-radius: 2px;
      height: 35px;
      font-size: 12px;
      padding: 0 10px;
      color: #495057;
      position: relative;
      outline: none;
    }
  }

  .text-value-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .text-value {
    font-weight: 700;
    color: var(--unnamed-color-1d1d27);
    font-size: $font-size-medium;
  }
  .calendar-icon {
    margin-right: .5rem;
  }
  .btn.btn-link {
    padding: 0px;
  }
  .btn-link:hover,
  .btn-link:focus,
  .btn-link.focus,
  .btn-link.hover {
    text-decoration: none;
    color: #716DFF;
  }
  .hidden-input {
    display: none;
  }

  .react-datepicker__navigation,
  .react-datepicker__day {
    &:focus {
      outline: none;
    }
    &.react-datepicker__day--disabled {
      color: #ccc;
    }
  }

  .link-button-text {
    font-size: $font-size-small;
    &:active {
      font-weight: 900;
    }
  }

  .react-datepicker__day--selected, 
  .week_hightlight {
    color: white !important;
    background-color: #716DFF;
    border-radius: 50%;
  }

  .week_hightlight {
    background-color: #bdedff;
    color: #333333 !important;
    border-radius: 0px;
    &:first-child,
    &:last-child {
      // border-radius: 50%;
      color: white !important;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    &:last-child {
      background-image: 
      url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 100 100'><rect x='0' y='0' width='50' height='100' fill='%23bdedff' /><circle cx='50' cy='50' r='50' fill='%231DB4CF' /></svg>");
    }
    &:first-child {
      background-image: 
      url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 100 100'><rect x='50' y='0' width='50' height='100' fill='%23bdedff' /><circle cx='50' cy='50' r='50' fill='%231DB4CF' /></svg>");
    }
  }
  
  .date-input {
    &:not(.readOnly) {
      background-color: #fff;
    }
    border: 1px solid #D5DADF;
    border-radius: 2px;
    height: 35px;
    font-size: 12px;
  }
  &.custom-datepicker-disabled {
    .date-input {
      background-color: hsl(0,0%,95%);
    }
  }
  .custom-input-icon {
    position: absolute;
    top: -1px;
    right: -4px;
    height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: inherit;
    cursor: pointer;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px;
    color: #495057;
    &:focus,
    &:hover,
    &:active {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      color: #495057;
    }
  }
  .label {
    color: var(--unnamed-color-41485a);
    opacity: 0.6;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 5px;

    &.required:after {
      content: '*';
      color: var(--unnamed-color-ff8886);
    }
  }
}

.picker-navigator {
  display: inline-flex;
  align-items: stretch;
  font-size: inherit;
  color: #333333;
  .picker-nav-icon {
    font-size: 1.4rem;
  }
  .nav-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
      box-shadow: none !important;
      border: 1px solid #D5DADF;
    }
    &.disabled {
      color: $text-secondary-color-light;
      pointer-events: none;
    }
  }

  .nav-center {
    width: 100px;
    margin: 0 10px;
  }

  .nav-left,
  .nav-right {
    width: 40px;
  }
  .nav-right {
    svg {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1023px) {
  div.picker-navigator {
    .nav-left,
    .nav-right {
      width: 30px;
    }
    .nav-center {
      width: 85px;
      margin: 0 7px;
    }
  }
}

.custom-monthpicker {
  .react-datepicker__month-text {
    height: $row-height;
    line-height: $row-height;
    font-family: Satoshi;
  }
}
