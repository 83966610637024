.line-chart {
  .line-chart-legend {
    list-style: none;
    padding: 10px 20px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    li {
      font-size: 14px;
      font-weight: normal;
      flex: 1 1 auto;
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .legend-item {
      & > span {
        width: 16px;
        height: 10px;
        float: left;
        margin-top: 5px;
        margin-right: 10px;
      }
    }
  }
}