.income-statement-report {

  .filter-label {
    min-height: 0;
    flex-grow: 2;

    .chip {
      margin: 0 10px;
    }
  }

  .report-header {
    .export-options {
      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .report-list-header {
    display: grid;
    grid-template-columns: 1fr 150px;
  }
  .account-code {
    margin-top: 0;
    div[class*="-menu"] {
      min-width: 300px;
    }
  }
}
