$iron: #dddedf;
$tundora: #4a4a4a;
$dodger-blue: #3B86FF;
$primary:#FCDA67;
$secondary: #171E44;
$success:#6BBB77;
$warning:#FF8D5C;
$btn-border-radius:1000em !important;
$land-bg:#FFFBFD;
$white-color:#FFF;
$text-secondary-color-light: #646464;
$color-secondary: $dodger-blue;

$font-size-small: 13px !default;
$line-height-small: 16px !default;

$font-size-medium: 15px !default;
$line-height-medium: 19px !default;

$font-size-large: 17px !default;
$line-height-large: 21px !default;

$font-size-xlarge: 20px !default;
$line-height-xlarge: 25px !default;

$font-size-xxlarge: 22px !default;
$line-height-xxlarge: 25px !default;

$text-secondary-color: #4a4a4a;

$screen-min-width: 480px;
$mobile-breakpoint: 768px;
$tablet-breakpoint: 1024px;
$screen-max-width: 1366px;

@import "bootstrap";