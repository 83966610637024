@import "../../../styles/variables";

.custom-modal {
  &.no-padding .modal-container {
    padding: 0;
  }
  .modal-content {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 4px 4px;
    border: none;
  }
  .modal-title-wrapper {
    padding: 40px 40px 20px;
    .modal-title-custom {
      color: var(--unnamed-color-1d1d27);
      font: normal normal 600 20px/25px Satoshi;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      text-align: center;
    }
    .close-icon {
      z-index: 8;
      position: absolute;
      right: 10px;
      top: 10px;
      &:hover {
        .c {
          fill: var(--unnamed-color-716dff)
        }
      }
    }
  }
  .modal-body-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 40px 40px;
  }
  .modal-loading-state {
    position: absolute;
    z-index: 9;
  }
  .close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    border-radius: 30px;
    &:hover {
      background-color: #9f9794;
      color: white;
    }
    z-index: 8;
  }
  .modal-container {
    z-index: 7;
  }
}