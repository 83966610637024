@import "../../styles/variables";
.forgot-password-page {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
   input{
    border-radius: 14px;
    height: 55px;
    &:focus{
      border-color: #000 ;
    }
   }
  .forgot-password-img {
    width: 800px;
    max-width: 90%;
    height: 610px;
    max-height: 80%;
    position: absolute;
    bottom: 0px;
    left: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    @media (max-width: 768px) {
      max-width: 100%;
      max-height: 100%;
    }
    @media (min-width: 1441px) {
      width: 90%;
      max-width: 90%;
      height: 90%;
      max-height: 80%;
    }
  }
  .logo-container {
    position: absolute;
    z-index: 1;
    left: 110px;
    top: 10%;
    @media (max-width: 1024px) {
      left: 50px;
    }
    @media (max-width: 768px) {
      left: unset;
      left: 50%;
      transform: translatex(-50%);
      top: 20px;
    }
    .logo {
      img {
        height: 70px;
      }
    }
    .desc {
      color: var(--unnamed-color-ffffff);
      text-align: left;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.19px;
      text-transform: uppercase;
    }
  }
  .forgot-password-form {
    padding: 30px 40px;
    position: absolute;
    width: 450px;
    max-width: 90%;
    right: 5%;
    top: 10%;
    background: var(--unnamed-color-FFFBFD) 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #332FAE61;
    border: 1px solid #EBEDF280;
    border-radius: 40px;
    z-index: 2;
    @media (max-width: 768px) {
      right: unset;
      left: 50%;
      transform: translatex(-50%);
      top: 150px;
    }
    @media (min-width: 1441px) {
      width: 650px;
      span.custom-text-body.button-text {
        font-size: 16px !important;
      }
    }
    & > div {
      width: 100%;
      & > div:not(:first-child) {
        margin-top: 15px;
      }
      .title {
        color: var(--unnamed-color-41485a);
        letter-spacing: 0.9px;
        text-align: center;
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 30px;
      
      }
      #arrow-right {
        transform: rotate(180deg);
        position:absolute;
        top:25px;
        left: 25px;
        background-color: $secondary;
        border-radius: 50%;
      }
      #Ellipse_15 {
        fill: transparent;
      }
      #Arrow {
        fill: $land-bg;
      }
      .sub-title {
        font-size: 14px;
        color: var(--unnamed-color-41485a);
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 20px;
      }
      .or-text {
        color: #ACB5C0;
        font-size: 14px;
      }
      .link-button-right-text{
        padding-left: 0;
      }
      .login-text {
        font-size: 14px;
        button {
          display: inline-block;
          margin-top: -3px;
          padding-left: 0;
          .link-button-text {
            font-size: 14px;
            font-weight: 600;
            color: var(--unnamed-color-44dec5);
          }
        }
      }
      .forgot-password-button {
        margin-top: 20px;
        width: 260px;
      }
    }
  }
  @media (min-width: 1366px) {
    .forgot-password-form {
      padding-top: 54px;
      padding-bottom: 60px;
      padding-left: 60px;
      padding-right: 60px;
      position: absolute;
      width: 590px;
    }
  }
  @media (min-width: 1920px) {
    .forgot-password-form {
      padding-top: 80px;
      padding-bottom: 80px;
      padding-left: 70px;
      padding-right: 70px;
      position: absolute;
      width: 664px;
    }
  }
}