.detail-form {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  & > * {
    width: calc(100% / 2 - 5px);
    &:nth-child(2n - 1) {
      margin-right: 10px;
    }
  }
  .custom-datepicker {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .select-control,.async-select-control {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .custom-button {
    margin-top: 33px;
    margin-bottom: 7px;
  }

  .currency-converter {
    width: fit-content;
    background: #fff;
    color: #41485a;
    font-size: 12px;
    padding: 0 10px;
  }

  .function-buttons {
    flex: 2 auto;
    min-width: fit-content;
    display: flex;
    justify-content: space-evenly;
  }
}

.supplier {
  div[class*="-ValueContainer"] {
    font-size: 12px;
  }
  .add-new-supplier {
    padding: 10px 12px;
    border-top: 1px solid var(--unnamed-color-f5f6f7);
    text-align: center;

    button {
      width: 200px;
    }
  }
  div[class*="-group"] {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    height: 28px;
    line-height: 28px;
  }
  div[class*="-option"] {
    display: grid;
    grid-template-columns: 30px 1fr;
    & > div:first-child {
      img {
        width: 20px;
        height: 20px;
      }
    }
    & > div:last-child {
      & > div:first-child {
        font-size: 14px;
        font-weight: 600;
      }
      & > div:last-child {
        font-size: 11px;
        font-weight: 600;
        color: #acb5c0;
      }
    }
  }
  .group-name {
    color: #acb5c0;
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.bank-account {
  .add-new-bank-account {
    padding: 10px 12px;
    border-top: 1px solid var(--unnamed-color-f5f6f7);
    text-align: center;

    button {
      width: 200px;
    }
  }
}

.post-detail-page .create-supplier-modal,
.post-detail-page .create-bank-account-modal {
  position: absolute;
  top: 128px;
  right: 19px;
  z-index: 10;
  display: none;
  width: 350px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d5dadf;
  border-radius: 2px;

  &.show {
    display: block;
  }

  & > .clear-icon {
    position: absolute;
    right: 10px;
    top: 6px;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    background: var(--unnamed-color-41485a);
    padding: 10px 20px;
    border-radius: 2px 2px 0 0;
  }

  .content {
    padding: 10px 20px;

    & > .label,
    .input-control .label,
    .select-control .label {
      text-align: left;
      font: normal normal normal 12px/15px Satoshi;
      letter-spacing: 0.36px;
      opacity: 0.7;
      padding-top: 10px;
    }

    & .radio-button .label {
      font: normal normal normal 13px/20px Satoshi;
    }
  }

  .actions {
    padding: 20px;
    text-align: center;
    background: var(--unnamed-color-f5f6f7);
    border-radius: 0 0 2px 2px;
  }
}

.post-detail-page .create-bank-account-modal {
  top: 278px;

  .one-line {
    display: flex;
    align-items: flex-end;

    .bank-name {
      margin: 0 0 12px 10px;
      & span {
        display: flex;
        align-items: center;
        color: var(--unnamed-color-41485a);
        font: normal normal 600 13px/20px Satoshi;

        & > svg {
          margin-right: 5px;
        }
      }
    }

    .currency {
      margin-left: 10px;
      width: 150px;
    }
  }
}

.popover-text > div:not(:last-child) {
  margin-bottom: 10px;
}
