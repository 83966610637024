.ask-question-modal {
  .modal-dialog {
    max-width: 700px;
  }

  .modal-title-wrapper {
    padding-bottom: 10px !important;
  }

  .back-icon {
    margin-right: 10px;

    svg {
      margin-bottom: 3px;

      .a {
        fill: #1D1D27;
      }
    }
  }

  .sub-title {
    font: normal normal 600 13px/20px Satoshi;
    letter-spacing: 0px;
    color: #ACB5C0;
    text-align: center;
    word-break: break-all;
    padding-bottom: 10px;
  }

  .actions {
    text-align: center;
    padding-top: 30px;
  }

  .send-button {
    margin-left: 10px;
  }

  .cancel-button {
    margin-left: 10px;
    background: var(--unnamed-color-8d8a89);
  }
}
