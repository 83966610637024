.accounting-questions-card {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #EAEBEE40;
  border: 1px solid #EBEDF280;
  border-radius: 4px;
  padding: 20px;
  font-size: 12px;
  .header-row {
    display: grid;
    grid-template-columns: 1fr 50px;
    column-gap: 3px;
    & > div {
      padding-top: 2px;
      padding-bottom: 2px;
      &:first-child {
        font-weight: 600;
        &.hasLink:hover {
          color: var(--unnamed-color-716dff);
        }
      }
      &:nth-child(2) {
        color: #ACB5C0;
        height: 22px;
        text-align: right;
      }
    }
    &.three-column {
      grid-template-columns: 10fr 50px 1fr;
      & > div {
        &:nth-child(2) {
          padding-right: 3px;
        }
        &:last-child {
          height: 22px;
          text-align: center;
          color: #4EBCFA;
          background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
          border-radius: 2px;
          white-space: nowrap;
          padding: 0px 4px;
          line-height: 22px;
        }
      }
    }
  }
  .content-row {
    color: var(--unnamed-color-41485a);
    font-size: 11px;
    padding-top: 10px;
  }
  .answer-question-input {
    margin-top: 15px;
  }
  .button-list {
    & > button {
      height: 35px;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .actions {
    padding-top: 15px;
  }
  .answer {
    color: #8e8e8e;
    font-size: 14px;
    padding-top: 10px;
    span {
      color: var(--unnamed-color-716dff);
    }
  }
  .attachment {
    margin-top: 10px;
    color: var(--unnamed-color-41485a);
    text-align: left;
    font: normal normal normal 12px/15px Satoshi;
    letter-spacing: 0.36px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
      margin-right: 5px;
      margin-top: -3px;
    }
    &.hasLink:hover {
      color: var(--unnamed-color-716dff);
      cursor: pointer;
    }
  }
}
