@import "../../styles/variables";
.post-detail-list {
  position: relative;

  .post-detail-list-header {
    min-height: 45px;
    background: var(--unnamed-color-41485a) 0% 0% no-repeat padding-box;
    font-size: 14px;
    color: var(--unnamed-color-ffffff);
    padding: 5px;
    & > div {
      padding: 0 5px;
    }
  }
  & > div:not(.labels-select) {
    display: grid;
    align-items: center;    
  }
  &.advanced-mode > div:not(.labels-select) {
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr 0.4fr 0.2fr;
  }
  .post-detail-list-item {
    border: 1px solid #D5DADF;
    border-top: none;
    padding: 5px;
    & > div {
      padding: 0 5px;
    }
    &.total {
      & > div:first-child {
        color: var(--unnamed-color-41485a);
        font-size: 14px;
      }
      .advanced-mode-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > div:first-child {
          color: var(--unnamed-color-41485a);
          font-size: 14px;
          line-height: 14px;
          margin-right: 10px;
        }
      }
      .difference-wrap {
        display: flex;
        align-items: center;
        & > div:first-child {
          color: #ACB5C0;
          font-size: 14px;
          line-height: 14px;
          margin-right: 5px;
          margin-top: 2px;
        }
      }
    }
    &.footer.footer {
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: column;
      justify-content: end;
      padding: 20px;
      & button {
        background: var(--unnamed-color-171E44);
        margin-right: 10px;
      }
      & .book-button {
        background: $primary;
        margin-right: 10px;
      }
    }
    &.item:hover {
      background: rgba(78, 188, 250, 0.05) 0% 0% no-repeat padding-box;
    }
    &.item:hover span.clear-icon {
      opacity: 1;
    }
  }
  span.add-icon {
    width: 30px;
    height: 30px;
    background: #ACB5C0 0% 0% no-repeat padding-box;
    border-radius: 23px;
    opacity: 0.5;
    display: inline-block;
    padding-left: 6px;
    padding-top: 0;
    margin: 10px 0;
  }
  span.add-icon.pill {
    width: 110px;
  }  
  span.clear-icon {
    opacity: 0;
  }
  .label-wrap > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font: normal normal normal 14px/18px Satoshi;
    color: #ACB5C0;
  }
}
