.payment-draft-transfer-list {
  margin: 50px 0px 0;
  position: relative;

  .payment-draft-transfer-list-header {
    height: 45px;
    background: var(--unnamed-color-41485a) 0% 0% no-repeat padding-box;
    font-size: 14px;
    color: var(--unnamed-color-ffffff);
    padding-left: 10px;
    padding-right: 10px;
    & > div {
      padding-left: 10px;
    }
  }
  & > div {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 0.75fr 1fr 0.3fr;

    &.transfer {
      grid-template-columns: 0.7fr 1fr 1fr 1fr 0.75fr 1fr 0.3fr;
    }
    &.account-entry {
      grid-template-columns: 2.7fr 1fr 0.75fr 1fr 0.3fr;
    }
  }
  .payment-draft-transfer-list-item {
    border: 1px solid #D5DADF;
    border-top: none;
    padding: 5px;
    & > div {
      padding: 0 5px;
    }
    &.item:hover {
      background: rgba(78, 188, 250, 0.05) 0% 0% no-repeat padding-box;
    }
    &.item:hover span.clear-icon {
      opacity: 1;
    }
  }
  .payment-draft-transfer-list-footer {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    & > button {
      margin-top: 10px;
    }
  }

  span.add-icon {
    width: 30px;
    height: 30px;
    background: #ACB5C0 0% 0% no-repeat padding-box;
    border-radius: 23px;
    opacity: 0.5;
    display: inline-block;
    padding-left: 6px;
    padding-top: 0;
    margin: 10px 0;
  }
  span.add-icon.pill {
    width: 110px;
  }  
  span.clear-icon {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}
