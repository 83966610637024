.supplier {
  div[class*="-ValueContainer"] {
    font-size: 12px;
  }
  .add-new-supplier {
    padding: 10px 12px;
    border-top: 1px solid var(--unnamed-color-f5f6f7);
    text-align: center;

    button {
      width: 200px;
    }
  }
  div[class*="-group"] {
    background: var(--unnamed-color-f5f6f7) 0% 0% no-repeat padding-box;
    height: 28px;
    line-height: 28px;
  }
  div[class*="-option"] {
    display: grid;
    grid-template-columns: 30px 1fr;
    & > div:first-child {
      img {
        width: 20px;
        height: 20px;
      }
    }
    & > div:last-child {
      & > div:first-child {
        font-size: 14px;
        font-weight: 600;
      }
      & > div:last-child {
        font-size: 11px;
        font-weight: 600;
        color: #acb5c0;
      }
    }
  }
  .group-name {
    color: #acb5c0;
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }
}
